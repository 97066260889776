let ENV = {
  PROD: {
    WEB_URL: 'https://now.menu',
    API_HOST: 'https://plugd.app',
    IMAGE_HOST: 'https://plugd.app:4000',
    API_ROOT: '/plugdapi/WebServicesV3/',
    PANEL_HOST: 'https://panel.plugd.app',
    BASE_URL: '/',
    FB_APP_ID: '1372007656237188',
    FIREBASE_CONFIG: {
      apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
      authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
      databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
      projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
      storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
      appId: process.env.REACT_APP_FIREBASE_APP_ID,
      measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
    }
  },
  STAGE: {
    WEB_URL: 'https://dev.now.menu',
    API_HOST: 'https://dev.plugd.app',
    IMAGE_HOST: 'https://dev.plugd.app:4000',
    API_ROOT: '/plugdapi/WebServicesV3/',
    PANEL_HOST: 'https://paneldev.plugd.app',
    BASE_URL: '/',
    FB_APP_ID: '1372007656237188',
    FIREBASE_CONFIG: {
      apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
      authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
      databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
      projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
      storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
      appId: process.env.REACT_APP_FIREBASE_APP_ID,
      measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
    }
  },
  STAGING: {
    WEB_URL: 'https://staging.now.menu',
    API_HOST: 'https://staging.plugd.app',
    IMAGE_HOST: 'https://dev.plugd.app:4000',
    API_ROOT: '/plugdapi/WebServicesV3/',
    // PANEL_HOST: 'https://paneldev.plugd.app',
    BASE_URL: '/',
    FB_APP_ID: '1372007656237188',
    FIREBASE_CONFIG: {
      apiKey: "AIzaSyA2ZjRYPHL5CKdp54lEC3NfJaeRQJrnIJg",
      authDomain: "plugddev.firebaseapp.com",
      databaseURL: "https://plugddev.firebaseio.com",
      projectId: "plugddev",
      storageBucket: "plugddev.appspot.com",
      messagingSenderId: "35539029089",
      appId: "1:35539029089:web:a364a703496ebd27ef6e20",
      measurementId: "G-XWHKL6FLT9"
    }
  },
  DEV: {
    WEB_URL: 'http://localhost:3000',
    API_HOST: 'http://localhost',
    IMAGE_HOST: 'http://localhost:4000',
    API_ROOT: '/plugdapi/WebServicesV3/',
    PANEL_HOST: 'http://localhost:3002',
    // WEB_URL: 'https://plugdweb.qwikq.net',
    // API_HOST: 'https://phpplugd.qwikq.net',
    // IMAGE_HOST: 'https://nodeplugd.qwikq.net',
    // API_ROOT: '/plugdapi/WebServicesV3/',
    // PANEL_HOST: 'https://plugdpos.qwikq.net',
    BASE_URL: '/',
    FB_APP_ID: '162127988411616',
    FIREBASE_CONFIG: {
      apiKey: "AIzaSyA2ZjRYPHL5CKdp54lEC3NfJaeRQJrnIJg",
      authDomain: "plugddev.firebaseapp.com",
      databaseURL: "https://plugddev.firebaseio.com",
      projectId: "plugddev",
      storageBucket: "plugddev.appspot.com",
      messagingSenderId: "35539029089",
      appId: "1:35539029089:web:a364a703496ebd27ef6e20",
      measurementId: "G-XWHKL6FLT9"
    }
  }
};

let CONSTANTS = {
  BASE_URL: `${ENV[process.env.REACT_APP_STAGE].BASE_URL}`,
  API_HOST: `${ENV[process.env.REACT_APP_STAGE].API_HOST}`,
  API_URL: `${ENV[process.env.REACT_APP_STAGE].API_HOST}${ENV[process.env.REACT_APP_STAGE].API_ROOT
    }`,
  WEB_URL: `${ENV[process.env.REACT_APP_STAGE].WEB_URL}`,
  NODE_API_URL: process.env.REACT_APP_NODE_API_URL,
  CLOUD_IMAGE_URL: process.env.REACT_APP_CLOUD_IMAGE_URL,
  LOCATION_IMAGE_URL: `${ENV[process.env.REACT_APP_STAGE].API_HOST}:4000/images/`,
  LOCATION_SITEMAP_URL: `${ENV[process.env.REACT_APP_STAGE].API_HOST}:4000/sitemaps/`,
  FIREBASE_CONFIG: ENV[process.env.REACT_APP_STAGE].FIREBASE_CONFIG,
  TRACKING_CODE: process.env.REACT_APP_ANALYTICS_TRACKING_CODE,
  APP_SECRET: process.env.REACT_APP_SECRET,
  GOOGLE_RECAPTCHA_KEY: '6Ler6uMnAAAAACR7MWVa0GFUKslCMEoGnWJsTfmb',
  ADYEN_CONFIG: {
    PAYMENT_METHODS: {
      card: {
        hasHolderName: true,
        holderNameRequired: true,
        name: 'Credit or debit card'
      }
    },
    RESULT_CODES: {
      SUCCESS: 'Authorised',
      FAILURE: 'Refused'
    }
  },
  GOOGLE_MAPS: {
    API_KEY: process.env.REACT_APP_GOOGLE_MAPS_KEY
  },
  CACHE_KEY_PREFIX: 'plugd_menu',
  APP_TITLE: 'Plugd',
  NAVBAR_HEIGHT: 64,
  CLUTTER: 0,
  REQUEST_HEADERS: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  URLS: {
    LOCATIONS: {
      GET: (locationId) => `customers/locations/${locationId}/details`,
      GETPAGE: (locationId, pageId) => `customers/locations/${locationId}/page/${pageId}`,
      UPDATE_MENU: (locationId) => `customers/locations/${locationId}/menu`,
      CHILDREN: 'customers/locations',
      SETTINGS: (locationId) => `customers/locations/${locationId}/branding`,
      TABLES: (locationId, table_id) => `customers/locations/${locationId}/table/${table_id}`,
      QRCODE: (locationId, unique_id) => `customers/locations/${locationId}/qrcode/${unique_id}`,
      TOP_RATED: (locationSlug, limit) =>
        `customers/locations/${locationSlug}/top_rated?limit=${limit}`,
      LIST: 'nearbyLocations'
    },
    CATEGORIES: {
      GET: 'get_categories'
    },
    ITEMS: {
      GET: 'get_simplified_menus',
      POPULAR_ITEMS: 'getTopRated',
      ITEM: {
        GET: 'item_reviews'
      }
    },
    LOGIN: {
      FB: 'fb_login_api',
      PHONE: 'customers/sessions/login',
      LOGOUT: 'customers/sessions/logout',
      WHATSAPP_OTP: 'customers/sessions/whatsapp',
      WHATSAPP_OTP_STATUS: 'customers/sessions/whatsapp/status',
      OTP: 'customers/sessions/otp',
      OTPUPDATE: 'customers/sessions/updateotp',
      CREATEVERIFICATION: 'customers/sessions/createVerification',
      CHECKVERIFICATION: 'customers/sessions/checkVerification',
      CREATEJAZZVERIFICATION: 'customers/sessions/verification',
      CHECKJAZZVERIFICATION: 'customers/sessions/authorization',
      OTPSETTINGS: 'customers/sessions/otpsettings',
      PROFILE: 'customers/users/profile',
      CHECK: 'customers/users/check',
      SEND: 'customers/users/send',
      SUBSCRIPTION: 'customers/sessions/subscription',
    },
    ORDERS: {
      CREATE: (locationId) => `customers/orders/${locationId}/place_order`,
      // CREATE: 'place_order',
      STATUS: 'customers/orders/status',
      SETTLE: `orders/request_bill`,
      UPDATEPAYMENT: (locationId) => `customers/orders/${locationId}/order_payment`,
      UPDATE: (locationId) => `customers/orders/${locationId}/order`,
      ORDERS: 'orders'
    },
    PAYMENTS: {
      DEFAULT: 'customers/payments'
    },
    USER_LOCATIONS: {
      DEFAULT: 'customers/user_locations',
      BULK_INSERT: 'customers/user_locations/bulk_create'
    },
    USER_PHONES: {
      DEFAULT: 'customers/user_phones',
      BULK_INSERT: 'customers/user_phones/bulk_create'
    },
    CONFIGURATIONS: {
      DEFAULT: 'customers/configurations'
    },
    NOTIFICATIONS: {
      DEFAULT: 'customers/notifications'
    },
    REVIEWS: {
      DEFAULT: 'customers/reviews'
    },
    DELIVERY: {
      GET: (orderId) => `customers/delivery/${orderId}`
    },
    PROMOTIONS: {
      DEFAULT: (locationId) => `customers/promotions/${locationId}`,
      CHECK: (code, locationId) => `customers/promotions/${locationId}/check/${code}`,
      USER_DEFAULT: (locationId) => `customer/promotions/${locationId}`,
      USER_CHECK: (code, locationId) => `customer/promotions/${locationId}/check/${code}`
    },
    LOYALTIES: {
      DEFAULT: (locationId) => `customers/loyalties/${locationId}`,
      CHECK: (code, locationId) => `customers/loyalties/${locationId}/redeem`,
      USER_DEFAULT: (locationId) => `customer/loyalties/${locationId}`,
      EARNED_REWARDS: (locationId) => `customer/earnedrewards/${locationId}`
    },
    BINDISCOUNTS: {
      DEFAULT: (locationId) => `customers/bindiscounts/${locationId}`,
      CHECK: (code, locationId) => `customers/bindiscounts/${locationId}/check/${code}`,
      USER_DEFAULT: (locationId) => `customer/bindiscounts/${locationId}`,
      USER_CHECK: (code, locationId) => `customer/bindiscounts/${locationId}/check/${code}`
    }
  },
  ACTIONS: {
    LOCATIONS: {
      LIST: 'GET_LOCATIONS',
      CHILDREN: 'CHILD_LOCATIONS',
      OUTLETS: 'OUTLETS',
      GET: 'GET_LOCATION',
      PAGE: 'GET_PAGE',
      SETTINGS: 'GET_SETTINGS'
    },
    PROMOTIONS: {
      LIST: 'GET_ALL_PROMOTIONS',
      GET_PROMOTION: 'GET_PROMOTION'
    },
    LOYALTIES: {
      LIST: 'GET_ALL_LOYALTIES',
      GET_LOYALTY: 'GET_LOYALTY',
      GET_EARNED_REWARDS: 'GET_ALL_EARNED_REWARDS',
    },
    BINDISCOUNTS: {
      LIST: 'GET_ALL_BINDISCOUNTS',
      GET_BINDISCOUNT: 'GET_BINDISCOUNT'
    },
    ORDER_PREFERENCE: {
      SET_ORDER_PREF_ID: 'SET_ORDER_PREF_ID',
      SET_ORDER_PREF_TYPE: 'SET_ORDER_PREF_ID',
      SET_ORDER_PREF_CART_STATE: 'SET_ORDER_PREF_CART_STATE'
    },
    CATEGORIES: {
      LIST: 'CATEGORIES',
      SELECT: 'SELECT_CATEGORY'
    },
    NOTIFICATIONS: {
      LIST: 'GET_NOTIFICATIONS'
    },
    ITEMS: {
      LIST_CAT: 'LIST_CAT',
      LIST: 'ITEMS',
      POPULAR_LIST: 'POPULAR_ITEMS',
      GET: 'GET_ITEM',
      GET_DETAILS: 'GET_DETAILS'
    },
    CART: {
      UPDATE: 'UPDATE_CART'
    },
    ORDERS: {
      CREATE: 'CREATE_ORDER',
      STATUS: 'ORDER_STATUS'
    },
    PAYMENTS: {
      PROCESSOR: {
        GET: 'get_processor'
      }
    },
    ALERTS: {
      DURATION: 5000,
      SUCCESS: 'alert_success',
      ERROR: 'alert_error',
      WARNING: 'alert_warning',
      CLEAR: 'alert_clear',
      TOAST_SETTINGS: 'toast_settings'
    },
    CONFIGURATIONS: {
      STATUSES: 'GET_STATUSES'
    }
  },
  ERRORS: {
    DEFAULT:
      'Oops, Something went wrong. Please refresh the page and try again. If problem persists please contact us @ developer@plugd.app.',
    LOCATION_NOT_FOUND: 'Invalid restaurant link. Please enter the correct URL.',
    LOCATION_INTERNET: 'Internet Connection Error. Please check your internet conection and try again.',
    MINIMUM_ORDER_ERROR: (minimumAmount) => {
      return `Your order is below the minimum amount of ${minimumAmount}`;
    },
    TIME_NOT_ALLOWED: (restaurant, orderType) => {
      return `${restaurant} does not allow ${orderType} order at this time. Please select another time.`;
    },
    CLOSED: (restaurant) => {
      return `Today, ${restaurant} has been closed.`;
    },
    ADDITIONAL_FIELDS: {
      REQUIRED: {
        DELIVERY_PHONE: 'Please enter a phone number',
        DELIVERY_LOCATION: 'Please select a location',
        DELIVERY_ADDRESS: 'Please enter an address',
        CAR_NUMBER: 'Please enter your car number',
        CAR_MODEL: 'Please enter your car model'
      },
      NOT_ALLOWED: {
        DELIVERY_LOCATION: (restaurant) => {
          return `${restaurant} can not deliver in your area.`;
        },
        NO_DELIVERY_LOCATION: (restaurant) => {
          return `${restaurant} does not have any delivery zone.`;
        }
      }
    }
  },
  SETTINGS: {
    RATING: {
      SCALE_FACTOR: 1.0,
      LOCATION_TIMEOUT: 30000
    },
    STATUS_POLLING_INTERVAL: 10000
  },
  INPUTS: {
    DISPLAY_TYPES: {
      RADIO: 'radio',
      SELECT: 'select',
      CHECKBOX: 'checkbox'
    }
  },
  LOGIN: {
    FB: {
      PROVIDER_ID: 'facebook.com',
      APP_ID: ENV[process.env.REACT_APP_STAGE].FB_APP_ID,
      STATUS: {
        UNKNOWN: 'unknown'
      }
    },
    VARIABLES: {
      USER_TYPES: {
        NEW: 'new',
        OLD: 'old',
        EXISTING: 'existing'
      },
      RELATIONSHIP_STATUS: {
        UNKNOWN: 'unknown'
      },
      GENDERS: {
        MALE: 'MALE',
        FEMALE: 'FEMALE'
      },
      DEFAULT_PASSWORD: 'password',
      DEFAULT_IMAGE: 'user.jpg'
    }
  },
  PROMOTIONS: {
    VARIABLES: {
      TYPE: {
        AUTOMATIC: 1,
        MANUAL: 0
      },
      APPLICABLE_TIME_TYPE: {
        ALL_TIME: 0,
        SPECIFIC_DATES: 1,
        WEEK_DAYS: 2
      },
      DISCOUNTS: {
        TYPE: {
          BASKET: 0,
          ALL_ITEMS: 1,
          SPECIFIC_ITEMS: 2
        },
        TYPE_STR: {
          BASKET: 'basket',
          ALL_ITEMS: 'all_items',
          CATEGORIES: 'categories',
          ITEMS: 'items'
        },
        AMOUNT_TYPE: {
          FIXED: 0,
          PERCENTAGE: 1
        },
        REWARD_TYPE: {
          FIXED: 0,
          PERCENTAGE: 1
        },
        ITEM_TYPES: {
          CATEGORY: 0,
          ITEM: 1
        }
      }
    }
  },
  ORDERS: {
    VARIABLES: {
      QR_ERROR:
        'Invalid Restaurant or QR Code. Please contact restaurant manager to resolve this issue.',
      TOTALS: {
        TAX: 'tax',
        SUB_TOTAL: 'cart_total',
        DISCOUNT: 'discount',
        TOTAL: 'order_total'
      },
      ORDER_TYPES: {
        DELIVERY: 'delivery',
        DINE_IN: 'dine_in',
        PICK_UP: 'pick_up',
        CURBSIDE_PICKUP: 'curbside_pickup'
      },
      ORDER_TYPE_STATUSES: {
        DELIVERY: [11, 12, 13, 22, 15, 19],
        DINE_IN: [11, 12, 13, 14, 15, 19],
        PICK_UP: [11, 12, 13, 21, 15, 19],
        CURBSIDE_PICKUP: [11, 12, 13, 21, 15, 19]
      },
      PAYMENT_MODES: {
        CASH: 'cash',
        CARD: 'card'
      },
      PAYMENT_PROVIDERS: {
        ADYEN: 'Adyen',
        CYBERSOURCE: 'CyberSource',
        XPAY: 'Xpay'
      },
      PAYMENT_STATUSES: {
        PAID: 'paid',
        NOT_PAID: 'not_paid',
        BILL_REQUESTED: 'bill_requested',
        COMPLETE_REFUND: 'complete_refund',
        PARTIAL_REFUND: 'partial_refund',
        HOLD: 'hold'
      },
      STATUSES: {
        PLACED: 'Placed',
        SEEN: 'Seen',
        BEING_PREPARED: 'Being Prepared',
        READY: 'Ready For Pickup',
        ON_THE_WAY: 'On The Way',
        COMPLETE: 'Complete',
        CANCELLED: 'Cancelled'
      },
      PAYMENT_METHODS: {
        scheme: 'Card',
        cash: 'Cash',
        default: 'Cash'
      },
      TIME_OPTIONS: {
        NOW: 'Now',
        TODAY: 'Today',
        TOMORROW: 'Tomorrow'
      },
      DEFAULT_LOCATION: {
        lat: 8.7832,
        lng: 124.5085
      },
      DELIVERY_ZONE_TYPES: {
        CIRCLE: 'circle',
        SHAPE: 'shape'
      }
    }
  },
  NOTIFICATIONS: {
    VARIABLES: {
      TEXTS: {
        order: (locationName) => {
          return `How was your meal at <span class='font-italic text-bold'>${locationName}</span> ? Rate what you had and add it to your foodie profile.`;
        }
      },
      SHOW_STATUS: {
        SHOW: 'show',
        UNSHOW: 'unshow'
      },
      OPENED_STATUS: {
        OPENED: 'opened',
        UN_OPENED: 'unopened'
      },
      SEEN_STATUS: {
        SEEN: 'seen',
        UNSEEN: 'unseen'
      },
      TYPES: {
        ORDER_REVIEW: 'order'
      }
    }
  },
  RATING_COLORS: {
    0: '#F90403',
    1: '#F90403',
    2: '#FFA400',
    3: '#92D050',
    4: '#00EAD9',
    5: '#00EAD9'
  },
  OTP_GETWAYS: {
    NONE: 0,
    FIREBASE: 1,
    UNIFONIC: 2,
    JAZZ: 3
  },
  UNIFONIC_CODES: {
    CORRECT: 101,
    INCORRECT: 107,
    ATTEMPTSEXCEED: 108,
    CODEEXPIRED: 109,
    ALREADYVERIDIED: 110,
  },
  DEFAULT_RATING_COLOR: '#ffc148',
  DEFAULT_RATING_SPACE: '0',
  DEFAULT_RATING_SIZE: '20px',
  MENU: {
    POPULAR_CATEGORY: 'popular'
  },
  DATES: {
    DAY_NUMBERS: [1, 2, 3, 4, 5, 6, 7],
    FORMAT: {
      DISPLAY: 'Do MMM, YYYY',
      DB: 'YYYY MM DD',
      COMMON: 'YYYY-MM-DD'
    }
  },
  TIMES: {
    FORMAT: {
      DISPLAY: 'hh:mm A',
      DB: 'HH:mm:ss'
    }
  },
  DATE_TIMES: {
    FORMAT: {
      DISPLAY: 'DD MMM YYYY hh:mm A',
      NEW_DISPLAY: 'DD MMM hh:mm A',
      DB: 'YYYY-MM-DD HH:mm:ss'
    }
  },
  EVENTS: {
    GENERAL: {
      GROUP_NAME: 'ecommerce',
      PAGE_LOAD: 'page_loaded_plugd',
      OUTLET_SELECTED: 'outlet_selected_plugd',
      VIEW_ITEM: 'view_item_plugd',
      ADD_TO_CART: 'add_to_cart_plugd',
      BEGIN_CHECKOUT: 'begin_checkout_plugd',
      ADD_SHIPPING_INFO: 'add_shipping_info_plugd',
      CLICKED_PLACE_ORDER: 'clicked_place_order_plugd',
      PURCHASED: 'purchase_plugd',
      LOGIN: 'login_plugd',
      OTP_REQUESTED: 'OTP_requested_plugd',
      REVERSE_OTP_REQUESTED: 'OTP_reverse_requested_plugd',
      OTP_VERIFIED: 'OTP_Verified_plugd',
      REGISTERED: 'Registered_plugd',
      ERROR: 'exception_server_error_plugd',
    }
  },
  WORKING_HOUR_DUMMY: (type, day) => {
    return {
      id: 0,
      location_id: 0,
      opening_time: '00:00:00',
      closing_time: '23:59:00',
      status: true,
      type: type,
      weekday: day
    };
  }
};

CONSTANTS.ORDERS.VARIABLES.WORKING_HOURS = {
  [CONSTANTS.ORDERS.VARIABLES.ORDER_TYPES.DELIVERY]: 'delivery',
  [CONSTANTS.ORDERS.VARIABLES.ORDER_TYPES.DINE_IN]: 'opening',
  [CONSTANTS.ORDERS.VARIABLES.ORDER_TYPES.PICK_UP]: 'collection',
  [CONSTANTS.ORDERS.VARIABLES.ORDER_TYPES.CURBSIDE_PICKUP]: 'collection'
};

export default CONSTANTS;
